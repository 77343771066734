body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#appAuthentication{
  min-height: 100vh;
  /*display: grid;*/
  /*flex-direction: column;*/

}
.thinHeading{
  font-weight: 300;
}

.iconHeading{
  padding-left: 10px;
}


.proBlockMessageWarning{
  border-radius: 8px 8px 0 0;
  animation: WARNING-BLINK 1s infinite;
  padding-bottom: 10px;
  padding-top: 10px;
  color: white;
}
@media only screen and (min-width: 600px) {
  .heroDiv{
    background: url("../src/SharedComponents/Hero.svg") no-repeat;
    background-size: 100% auto;
  }
}

@media only screen and (max-width: 600px) {
  .heroDiv{
    /*background: url("../src/SharedComponents/BannerBackground.svg") no-repeat;*/
    /*background-size: 100% auto;*/
    background: linear-gradient(90deg, rgba(177,198,14,1) 0%, rgba(0,212,255,1) 100%);
  }
}

.heroDiv > div{
  padding-top: 75px;
  padding-bottom: 75px;
  text-align: center;
  font-family: museo sans rounded, sans-serif;
}

.heroDiv h1{
  font-size: 350%;
}

.datasetIndex{
  min-height: 100vh;
}

.datasetRow{
  border-top: 1px solid lightgrey;
  width: 75%;
  margin: 0 auto;
  /*padding-top: 15px;*/
  /*padding-bottom: 15px;*/
}

.datasetRowBody{
  margin-top: 50px;
  margin-bottom: 150px;
}

.datasetDownloadButton{
  background-color: green;
  height: 100%;
  padding: 10px 8px 8px 10px;
  text-align: center;
  color: whitesmoke;
  cursor: pointer;
}

.datasetDownloadButton > span{
  font-size: 450%;
}

.datasetDownloadButton > p {
  font-size: 120%;
  font-weight: bold;
}

.datasetDownloadButton:hover{
  background-color: #00bb00;
}

.datasetRow:hover{
  background-color: whitesmoke;
}

.datasetRowLink{
  text-decoration: none;
  color: black;
}
.details-picture{
  height: 150px;
  width: 150px;
}
.fileDesc input{
  background-color: #203354;
  color: white;
  border: none;
  width: 340px;
  font-family: Consolas,monospace;

}

.fileDescDiv{
  display: grid;
  grid-template-columns: 85% auto;
  grid-template-rows: auto;
  grid-template-areas: "descTextArea descButtons";
  padding: 5px;
}

.fileDescTextArea{
  grid-area: descTextArea;
}

.fileDescTextArea textarea{
  height: 30px;
}

.fileDescTextArea p{
  background-color: whitesmoke;
}

.fileDescButtons{
  text-align: right;
  grid-area: descButtons;
}

.fileDesc{
  background-color: #203354;
  /*border-radius: 5px;*/
  margin-left: 2px;
}

.shadowDiv{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.datasetRowLink:hover{
  text-decoration: none;
  color: blue;
}
@keyframes WARNING-BLINK{
  0%		{ background-color: #aa8cc5;}
  25%		{ background-color: #aa8cc5;}
  50%		{ background-color: #663096;}
  75%		{ background-color: #663096;}
  100%	    { background-color: #663096;}
}

.pricingGrid{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas:
          "freePricingCard paidPricingCard";
  grid-column-gap: 25px;
}

.pricingFeature{
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
}

.freePricingCard{
  grid-area: freePricingCard;
  /*margin: 0 auto;*/
}

.paidPricingCard{
  grid-area: paidPricingCard;
  /*margin: 0 auto;*/
}

.customPricingCard{
  grid-area: customPricingCard
}

.pricingCard{
  /*padding-left: 30%;*/
  /*padding-right: 30%;*/
  padding-top: 25px;
}

#aboutHero {
  color: white;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(24deg, rgba(106,210,252,1) 13%, rgba(181,0,210,1) 92%);
  padding-bottom: 60px;
  padding-top: 60px;
}

.uploadOuterSection{
  background-color: #e1ad01;
  color: white;
  /*border-radius: 5px;*/
  height: 200px;
  padding-top: 1%;
  padding-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  overflow: auto;
  /*margin-bottom: 5px;*/
  cursor: pointer;
}

.uploadInnerSection{
  border: 2px dashed white;
  background-color: #b6962c;
  height: 95%;
  width: 99%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  overflow: auto;
  /*align-items: center;*/
  text-align: center;
}

.uploadLabel{
  height: 100%;
  width: 100%;
  text-align: center;
}

.uploadIcons{
  font-size: 300%;
  font-weight: 300;
}

.lightgray{
  background-color: lightgrey;
}

.uploadProgressBar{
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.fileDropTarget{
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: both;
  margin-bottom: 2%;
}

.fileDropSource{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
}

.fileDropSourceDrag{
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
  -ms-transform: rotate(20deg); /* IE 9 */
  -webkit-transform: rotate(20deg); /* Safari */
  transform: rotate(20deg);
}

.linkStyle{
  color: cornflowerblue;
  cursor: pointer;
}

.linkStyle:hover{
  text-underline: blue;
  color: blue;
}

.app{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.roundedButton{
  border-radius: 40px;
}

.downloadFileIndex{
  /*background-color: #ffdb99;*/
  /*border: 1px solid #ffdb99;*/
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
          "header header"
          "fileName removeDuplicates"
          "downloadButton downloadButton";
}

.mergedFileNameDiv{
  grid-area: fileName;
  justify-self: end;
  margin-right: 10%;
  align-self: center;
}

.removeDuplicatesDiv{
  grid-area: removeDuplicates;
  margin-left: 5%;
  align-self: center;
  font-size: 120%;
}

.downloadButtonDiv{
  margin-top: 25px;
  margin-bottom: 25px;
  grid-area: downloadButton;
}

.downloadHeader{
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: center;
  grid-area: header;
}

.editOptionDiv{
  padding-bottom: 10px;
}

.importSheetOptions{
  padding-top: 10px;
  padding-bottom: 10px;
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.semanticBorder{
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.focusBorder{
  border-color: #85B7D9;
  background: #FFFFFF;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pricingPage{
  display: flex;
  flex-direction: column;
  text-align: center;
  /*grid-template-columns: 50% 50%;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas:*/
          /*"freePricingCard paidPricingCard";*/
  min-height: 100vh;
  padding-bottom: 100px;
  /*grid-column-gap: 25px;*/
}

.freePricingCard{
  grid-area: freePricingCard;
}

.paidPricingCard{
  grid-area: paidPricingCard;
}

.pricingCard{
  /*padding-left: 30%;*/
  /*padding-right: 30%;*/
  padding-top: 10%;
}

.desc {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.desc li {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

#dashboardIndex{
  display: grid;
  grid-template-columns: 150px 90vw;
  grid-template-rows: auto;
  grid-template-areas: "dashboardMenu dashboardDisplay";
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-bottom: 200px;
}

#dashboardMenu{
  grid-area: dashboardMenu;
  /*background-color: black;*/
  padding-left: 10px;
  padding-top: 10px;
}

#dashboardDisplay{
  grid-area: dashboardDisplay;
}

#uploadFileStepsDiv{
  /*margin-top: 30px;*/
  padding-left: 50px;
  padding-right: 50px;
  /*height: 100px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  overflow: auto;
}

#uploadFileSteps{
  text-align: center;
}

#uploadFileOptions{
  /*margin-top: 30px;*/
  padding-left: 10px;
  padding-right: 10px;
  /*height: 200px;*/
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding-top: 17px;
  padding-bottom: 10px;
  width: 95%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.fileOptionsInputDiv{
  margin-top: 10px;
}

#uploadFilesSampleTable{
  margin-top: 30px;
  padding-left: 50px;
  padding-bottom: 30px;
  margin-right: 50px;
  overflow-x: scroll;
}

#editFileToolbarDiv{
  margin-top: 70px;
  padding-left: 50px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-row: auto;
  grid-template-areas: "editButton fileSummary removeFileButton";
}

#editButtonDiv{
  grid-area: editButton;
}

#fileSummaryDiv{
  grid-area: fileSummary;
  text-align: center;
}

#removeFileButtonDiv{
  grid-area: removeFileButton;
  float: right;
}

#editFileModalBody{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "editOptions sheetsOptions";
  grid-column-gap: 25px;
}

#xlsEditOptionsDiv{
  grid-area: editOptions;
  margin-top: 20px;
  border-right: 1px black solid;
}

#importSheetsDiv{
  grid-area: sheetsOptions;
  padding-top: 30px;
}

#editOptionsDiv{
  padding-top: 10px;
}

#selectColumn{
  cursor: pointer;
  height: 110%;
  width: 110%;
  text-align: center;
}

#cleanFunctionOptionsDiv{
  margin-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  overflow: auto;
}

#cleanFunctionRow{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "smartClean customClean";
  grid-column-gap: 25px;
  text-align: center;
}

#cleanFunctionSmartClean{
  float: right;
  grid-area: smartClean;
}

#cleanFunctionCustomClean{
  grid-area: customClean;
}

#customOptionsDiv{
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
  width: 50%;
}

.addSpan{
  cursor: pointer;
  border-radius: 25px;
  color: white;
  font-weight: 600;
  padding: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pageHero{
  color:white;
  text-align: center;
  background: linear-gradient(24deg, #7fd3fd 13%, #0081ff 92%);
  padding-top: 40px;
  padding-bottom: 60px;
}

.cardDiv{
  /*border: 1px black solid;*/
  margin: 15px;
  text-align: center;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pricingCardDiv{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
}

#simplePricingBlock{
  /*padding: 10px;*/
  width: 50%;
  margin: 0 auto;
  text-align: left;
}

#simplePricingBlockMobile{
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

.pricingRow{
  padding-top: 15px;
  padding-bottom: 15px;
  /*border-top: 1px solid black;*/
  border-bottom: 1px solid lightgrey;
  padding-left: 10px;
}

.pricingRowTop{
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  /*border-radius: 25px 25px 0px 0px;*/
  background-color: #7fd3fd;
  /*color: white;*/

}

.pricingRowBottom{
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid lightgrey;
  text-align: center;
}

.iconHeading{
  padding-left: 10px;
}

.footerShareGrid{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: "twitter facebook reddit linkedin email call";
}

.socialMediaButton{
  cursor: pointer;
}

#twitterButton{
  grid-area: twitter;
}

#facebookButton{
  grid-area: facebook;
}

#redditButton{
  grid-area: reddit;
}

#linkedinButton{
  grid-area: linkedin;
}

#emailButton{
  grid-area: email;
}

#callButton{
  grid-area: call;
}

.fa {
  padding: 10px;
  font-size: 15px;
  width: 15px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa-envelope{
  color: white;
  background: darkred;
}


.heroStepTop{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 10% 90%;
  grid-template-areas:
          "stepNumber stepIllustration";

  width: 40%;
  margin: 0 auto;

}

.heroStep{
  text-align: center;
}

.heroStepNumber{
  grid-area: stepNumber;
}

.heroStepIcon{
  grid-area: stepIllustration;
  margin-left: 35px;
}

.heroStepTopCenter{
  margin: 0 auto;
}

.heroStepCardDiv{
  /*border: 1px black solid;*/
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #F5F5F5;
  /*z-index: 1;*/
  /*position: absolute;*/
}

/*.dividedLine{*/
  /*z-index: -999;*/
/*}*/
.landingPageFeatureRow{
  padding-top: 100px;
  padding-bottom: 150px;
}

#landingPageProblems{
  display: grid;
  grid-template-rows: 20% 20% 20% 20% 20%;
  grid-template-columns: 50% 50%;
  grid-template-areas:
          "prob11 prob12"
          "prob21 prob22"
          "prob31 prob31"
          "prob41 prob42"
          "prob51 prob52"
;
  grid-row-gap: 10px;
  grid-column-gap: 5px;
}

#problemsRow1Col1{
  grid-area: prob11
}

#problemsRow1Col2{
  grid-area: prob12
}

#problemsRow2Col1{
  grid-area: prob21
}

#problemsRow2Col2{
  grid-area: prob22
}

#problemsRow3Col1{
  grid-area: prob31
}

#problemsRow4Col1{
  grid-area: prob41
}

#problemsRow4Col2{
  grid-area: prob42
}

#problemsRow5Col1{
  grid-area: prob51
}

#problemsRow5Col2{
  grid-area: prob52
}

.landingPageProblem{
  font-style: italic;
  font-size: 125%;
  text-align: center;
  font-weight: 300;
  padding-right: 5px;
}

@media only screen and (min-width: 600px) {
  .landingPageProblem{
    font-style: italic;
    font-size: 125%;
    text-align: center;
    font-weight: 300;
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media only screen and (min-width: 600px) {
  .deliveryCopy {
    height: 300px;
    font-size: 200%;
    /*font-style: italic;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.deliveryCopy{
  font-size: 200%;
  /*font-style: italic;*/
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.scheduleTimeForm{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*margin: 15px;*/
  /*text-align: center;*/
  padding: 50px 30px 50px 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.scheduleTimeDiv{
  padding-top: 100px;
  /*background-color: black;*/
  /*color: white;*/
}

.calendarDisplay{
  color: black;
}

.dashboardDisplayHeader{
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  grid-template-areas: "displayHeaderImage displayHeaderText";
  padding: 0;
}

#displayHeaderImage{
  grid-area: displayHeaderImage
}

#displayHeaderText{
  grid-area: displayHeaderText;
  margin-top: 9px;
}


.productQualityFeatureDiv{
  margin: 0 auto;
  width: 50%;
  text-align: center;
}

.faqBlock{
  padding-left: 20px;
  padding-right: 20px;
}

#supportFormClearAllButton{
  float: right;
  border-radius: 12px;
  font-size: 80%;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

#subjectCharLeft{
  color: gray;
  font-style: italic;
}

#supportUploadFiles{
  background-color: #0088ff;
  color: white;
}

#supportUploadFiles:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.inputError{
  color: red
}

.supportFormLabel{
  font-size: 110%;
}

.requiredLabel{
  padding-left: 3px;
  padding-right: 3px;
  color: red;
}

.buttonRaw {
  border: none;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

#fileTable{
  border-collapse: collapse;
  width: 100%;
}

#fileTable td, #fileTable th{
  border: 1px solid #ddd;
  padding: 8px;
}

#fileTable tr:nth-child(even){background-color: #f2f2f2;}

